
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    reactive,
  } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import * as Yup from 'yup';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import InvoiceComponent from '@/components/Reports/Invoice.vue';
  import InvoiceFilter from '@/components/dropdown/InvoiceFilter.vue';

  export default defineComponent({
    name: 'finance-listing',
    components: {
      Datatable,
      Form,
      ErrorMessage,
      Field,
      InvoiceComponent,
      InvoiceFilter,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);

      const phoneNumber = ref('');

      let searchQuery = {};

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('financecInvoiceListing'), [
          translate('operations'),
        ]);
        const invoiceId = route.query.invoiceId;
        console.log(invoiceId, 'Invoice ID');
        loading.value = true;
        await store.dispatch(Actions.GET_FINANCE_INVOICE, { invoiceId });
        await store.dispatch(Actions.GET_ALL_CUSTOMERS);
        store.dispatch(Actions.GET_ALL_FINANCE_INVOICE);
        loading.value = false;
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_FINANCE_INVOICE_STORE);
      });
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('date'),
          key: 'createdAt',
          sortable: true,
        },
        {
          name: translate('invoice Number'),
          key: 'invoiceNo',
          sortable: true,
        },
        {
          name: translate('customer Name'),
          key: 'customerName',
          sortable: true,
        },

        {
          name: translate('phone Number'),
          key: 'customerPhoneNumber',
          sortable: true,
        },
        {
          name: translate('total Invoice'),
          key: 'totalPrice',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'status',
          sortable: true,
        },
        {
          name: translate('Payment Link'),
          key: 'checkoutUrl',
          sortable: true,
        },

        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const excelHeader = reactive({
        Date: 'createdAt',
        'Invoice Number': 'invoiceNo',
        'Customer Name': 'customerName',
        'Phone Number': 'customerPhoneNumber',
        'Total Invoice': 'totalPrice',
        status: 'status',
      });

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const validationSchema = Yup.object().shape({
        phoneNumber: Yup.string()
          .notOneOf(['0'], () => translate('Mobile number is not valid'))
          .required(() => translate('PHONE_NUMBER_IS_REQUIRED_FIELD'))
          .matches(/^\d{8}$/, 'Mobile number is not valid')
          .label('Mobile number'),
      });

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_FINANCE_INVOICE_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_FINANCE_INVOICE, searchQuery);
        loading.value = false;
      };

      const selectedInvocieId = ref();
      const getInvoice = (invoice) => {
        phoneNumber.value = invoice.customerPhoneNumber.slice(3) || '';
        selectedInvocieId.value = invoice.trackNumber;
      };
      const generateOttu = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        values.id = selectedInvocieId.value;
        values.phoneNumber = `965${values.phoneNumber}`;

        await store.dispatch(Actions.GENERATE_OUTTO_FINANCE_INVOICE, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_GENERATED_INVOICE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            if (submitButton.value) {
              // eslint-disable-next-line
              submitButton.value!.disabled = false;
              submitButton.value.setAttribute('data-kt-indicator', 'off');
            }
            const close = document.getElementById('close');
            close!.click();
          });
        }
      };

      const generateManualOttu = async (invoice) => {
        loading.value = true;
        const values = {
          sessionId: invoice.sessionId,
          orderNo: invoice.trackNumber,
        };
        await store.dispatch(
          Actions.GENERATE_MANUAL_OUTTO_FINANCE_INVOICE,
          values
        );
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_GENERATED_INVOICE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            const close = document.getElementById('close');
            close!.click();
          });
        }
        loading.value = false;
      };

      const searchInvoice = async (query) => {
        searchQuery = query.value;
        loading.value = true;
        await store.dispatch(Actions.UPDATE_FINANCE_INVOICE_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_FINANCE_INVOICE, searchQuery);
        await store.dispatch(Actions.GET_ALL_FINANCE_INVOICE, searchQuery);
        loading.value = false;
      };
      const onReset = async () => {
        searchQuery = {};
        loading.value = true;
        await store.dispatch(Actions.UPDATE_FINANCE_INVOICE_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_FINANCE_INVOICE);
        await store.dispatch(Actions.GET_ALL_FINANCE_INVOICE, {});
        loading.value = false;
      };

      const excelData = computed(() =>
        store.getters.allFinancecInvoice.map((invoice) => {
          const status = invoice.status ? 'paid' : 'not paid';

          return {
            ...invoice,
            createdAt: invoice.createdAt.slice(0, 10),
            status: status,
          };
        })
      );
      const isCopied = ref();
      const copyToClipboard = (id, text) => {
        isCopied.value = {
          copied: true,
          id,
        };
        const inputElement = document.createElement('input');
        document.body.appendChild(inputElement);
        inputElement.value = text;
        inputElement.select();
        document.execCommand('copy');
        document.body.removeChild(inputElement);
        setTimeout(() => {
          isCopied.value = {
            copied: false,
            id: '',
          };
        }, 3000);
      };

      return {
        tableData: computed(() => store.getters.financecInvoiceList),
        tableHeader,
        translate,
        totalItems: computed(() => store.getters.financecInvoiceListCount),
        pageChanged,
        goTo,
        loading,
        can,
        phoneNumber,
        getInvoice,
        generateOttu,
        validationSchema,
        submitButton,
        generateManualOttu,
        searchInvoice,
        onReset,
        excelData,
        excelHeader,
        copyToClipboard,
        isCopied,
      };
    },
  });
